.share-bar {
  text-align: right;
  /* position: fixed; */
  /* size of human thumb (apparent so, the UX people told me) */ 
  /* bottom: 44px;
  right: 44px; */
  font-size: 22px;

  background-color: rgba(100, 100, 100, .6);
  padding-right: 10px;
  padding-bottom: 5px;

  display: inline-block;
  position: relative;
  margin: 0 15px 15px 30px;
  z-index: 1;
  overflow: hidden;
}

.share-button {
  line-height: 44px;
}

.share-bar__lower-menu {
  font-size: 14px;
}

@media (max-width: 767px) {
  .share-bar {
    float: right;
  }
}
@media (min-width: 768px) {
  .share-bar__lower-menu {
    font-size: 16px;
  }
}
